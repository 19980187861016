@font-face {
    font-family: 'GontserratBlack';
    src: url('/src/assets/fonts/Gontserrat-Black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GontserratBlackItalic';
    src: url('/src/assets/fonts/Gontserrat-BlackItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GontserratBold';
    src: url('/src/assets/fonts/Gontserrat-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GontserratBoldItalic';
    src: url('/src/assets/fonts/Gontserrat-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GontserratExtraBold';
    src: url('/src/assets/fonts/Gontserrat-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'GontserratExtraBoldItalic';
    src: url('/src/assets/fonts/Gontserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'GontserratExtraLight';
    src: url('/src/assets/fonts/Gontserrat-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'GontserratExtraLightItalic';
    src: url('/src/assets/fonts/Gontserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'GontserratItalic';
    src: url('/src/assets/fonts/Gontserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'GontserratLight';
    src: url('/src/assets/fonts/Gontserrat-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'GontserratMedium';
    src: url('/src/assets/fonts/Gontserrat-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'GontserratRegular';
    src: url('/src/assets/fonts/Gontserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GontserratSemibold';
    src: url('/src/assets/fonts/Gontserrat-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
