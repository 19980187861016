.map-container {
    /* position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; */
    width: 100%;
    height: 80%;
  }
  
  .sidebarStyle {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    background-color: #404040;
    color: #ffffff;
    z-index: 1 !important;
    font-weight: bold;
  }
 

  .marker {
    background-image: url('location-pin.png');
    background-size: cover;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    cursor: pointer;
  }

  .mapboxgl-popup {
    max-width: 200px;
 
  }
  
  .mapboxgl-popup-content {
    min-width: 200px;
    max-width : 250px;
    width: 20vw;
    height: 160px;
    border-radius: 12px;
    text-align: start;
    color: #000;
  };

  .mapboxgl-popup-content h3 {
    background: #91c949;
    color: #fff;
    margin: 0;
    padding: 10px;
    border-radius: 3px 3px 0 0;
    font-weight: 700;
    margin-top: -15px;
  }

  .mapboxgl-popup-close-button {
    color: #000;
  }

  .nearest-btn {
    padding: 5px 20px;
    position: absolute;
    top: 0;
    z-index: 199;
    cursor: pointer;
    background-color: #000;
    color: #ffffff;
    margin-top: 20px;
    border-radius: 50px;
  }

  .nearest-btn:hover {
    color: #dfdfdf;
    background-color: #1a1a1a;
  }

  #popup-title {
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0px;
  }

  .sidebar {
    color: #000;
    position: 'absolute';
    z-index: 100;
    top: 0;
    background-color: #ffffff;
    margin: 20;
    border-radius: 10px;
    width: 450px;
    height: '90%';
    box-shadow: '2px 2px 2px 1px rgba(0, 0, 0, 0.1)';
  }

